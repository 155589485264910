import ClaimsTable from "../claims/ClaimsTable";

interface Props {
  readonly claims: any[];
  readonly roleClaims: any[];
  readonly selectedRow: (value: any) => void;
}

export default function RolesClaimsTable({ claims, roleClaims, selectedRow }: Props) {
  return <ClaimsTable claims={claims} localClaims={roleClaims} onChangeClaim={selectedRow} />;
}
