import { useParams } from "react-router-dom";

import RolesFormWrapper from "./RolesFormWrapper";
import RolesTableWrapper from "./RolesTableWrapper";

export default function RolesTab() {
  const { tab = "roles-table" } = useParams();

  return (
    <>
      {tab === "roles-table" && <RolesTableWrapper />}
      {tab === "roles-form" && <RolesFormWrapper />}
    </>
  );
}
