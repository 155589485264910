import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { RolesApi } from "./RolesApi";

interface Props {
  readonly RolesApi: RolesApi;
}

export function useRolesApiContext(): Props {
  const data = useApiBase();
  const api = useMemo(() => new RolesApi(data), [data]);
  return {
    RolesApi: api,
  };
}
