import AppContainerLayout from "../components/app/AppContainerLayout";
import UsersTab from "../components/users/UsersTab";

export default function UsersContainer() {
  return (
    <AppContainerLayout>
      <UsersTab />
    </AppContainerLayout>
  );
}
