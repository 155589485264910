import { useEffect, useState } from "react";
import { useRolesApiContext } from "../../api/roles/RolesApiContext";

import TabPage from "../tabs/TabPage";
import Rolestable from "./RolesTable";
import Modal from "../ui/Modal";
import RolesClaimsTableWrapper from "./RolesClaimsTableWrapper";

export default function RolesTableWrapper() {
  const [roles, setRoles] = useState<any[]>([]);
  const [claimsModal, setClaimsModal] = useState(false);
  const [roleIdForClaims, setRoleIdForClaims] = useState(0);

  const { RolesApi } = useRolesApiContext();

  useEffect(() => {
    RolesApi.getAllRoles()
      .then((r) => setRoles(r))
      .catch((e) => console.log(e));
  }, [RolesApi]);

  return (
    <TabPage>
      <Rolestable
        data={roles}
        clickRole={(value) => {
          setClaimsModal(true);
          setRoleIdForClaims(value.id);
        }}
      />

      <Modal
        show={claimsModal}
        closeHandler={() => setClaimsModal(false)}
        className="d-flex justify-content-center align-items-center"
        contentClassName="rounded p-4"
        width="800px"
      >
        <RolesClaimsTableWrapper
          roleId={roleIdForClaims}
          closeClaimModal={() => setClaimsModal(false)}
        />
      </Modal>
    </TabPage>
  );
}
