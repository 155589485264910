// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-layout-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.app-layout-container .app-pages {
  width: 100%;
  height: 100%;
  transition: all ease 0.5s;
}
.app-layout-container .app-pages .app-pages-header {
  width: 100%;
  height: 50px;
}
.app-layout-container .app-pages .app-pages-page {
  width: 100%;
  height: calc(100vh - 50px);
  overflow: hidden;
  overflow-y: auto;
  background-color: #ebf0f5;
}`, "",{"version":3,"sources":["webpack://./src/components/app/assets/app-layout.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEI;EACE,WAAA;EACA,0BAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;AAAN","sourcesContent":[".app-layout-container {\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n  display: flex;\n\n  .app-pages {\n    width: 100%;\n    height: 100%;\n    transition: all ease 0.5s;\n\n    .app-pages-header {\n      width: 100%;\n      height: 50px;\n    }\n\n    .app-pages-page {\n      width: 100%;\n      height: calc(100vh - 50px);\n      overflow: hidden;\n      overflow-y: auto;\n      background-color: #ebf0f5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
