// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-button {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 2px 10px;
  color: #fff;
}
.logout-button:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}`, "",{"version":3,"sources":["webpack://./src/components/header/assets/logout-button.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,sBAAA;EACA,iBAAA;EACA,WAAA;AACJ;AACI;EACI,yEAAA;AACR","sourcesContent":[".logout-button{\n    background-color: transparent;\n    border: 1px solid #fff;\n    padding: 2px 10px;\n    color: #fff;\n\n    &:hover{\n        background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
