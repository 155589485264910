import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { WordsApi } from "./WordsApi";

interface Props {
  readonly WordsApi: WordsApi;
}

export function useWordsApiContext(): Props {
  const data = useApiBase();
  const api = useMemo(() => new WordsApi(data), [data]);
  return {
    WordsApi: api,
  };
}
