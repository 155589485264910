import { useCallback, useEffect, useState } from "react";
import { useWordsApiContext } from "../../api/words/WordsApiContext";
import { showError } from "../../utils/NotificationUtils";
import { update } from "immupdate";
import { toast } from "react-toastify";

import WordsForm from "./WordsForm";

interface Props {
  readonly wordId?: number;
}

export default function WordsFormWrapper({ wordId }: Props) {
  const [initialValues, setInitialValues] = useState({
    unitNumber: 0,
    pageNumber: "",
    english: "",
    uzbek: "",
    definition: "",
    example: "",
  });

  const { WordsApi } = useWordsApiContext();

  useEffect(() => {
    if (Boolean(wordId)) {
      WordsApi.getOneWord({ id: Number(wordId) })
        .then((r) => {
          setInitialValues((prev) =>
            update(prev, {
              unitNumber: r.UnitNumber,
              pageNumber: r.PageNumber,
              english: r.English,
              uzbek: r.Uzbek,
              definition: r.Definition,
              example: r.Example,
            }),
          );
        })
        .catch(showError);
    }
  }, [WordsApi, wordId]);

  const onSubmit = useCallback(
    (value: any) => {
      if (Boolean(wordId)) {
        const json = {
          id: Number(wordId),
          unitNumber: Number(value.unitNumber),
          pageNumber: value.pageNumber,
          english: value.english,
          uzbek: value.uzbek,
          chatId: 1,
          definition: value._definition || value.definition,
          example: value._example || value.example,
        };

        WordsApi.updateWord(json)
          .then((res) => {
            toast.success(res.message);
            window.location.reload();
          })
          .catch((er) => console.log(er));
      } else {
        const json = {
          unitNumber: Number(value.unitNumber),
          pageNumber: value.pageNumber,
          english: value.english,
          uzbek: value.uzbek,
          chatId: 1,
          definition: value._definition,
          example: value._example,
        };
        WordsApi.createWord(json)
          .then((res) => {
            toast.success(res.message);
            window.location.reload();
          })
          .catch((er) => console.log(er));
      }
    },
    [WordsApi, wordId],
  );

  return (
    <WordsForm
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      onSubmit={onSubmit}
    />
  );
}
