import AppContainerLayout from "../components/app/AppContainerLayout";
import RolesTab from "../components/roles/RolesTab";

export default function RolesContainer() {
  return (
    <AppContainerLayout>
      <RolesTab />
    </AppContainerLayout>
  );
}
