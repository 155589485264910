// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-control.form-control,
.input-control .form-control {
  width: 100%;
  border-radius: 2px;
}
.input-control.form-control.with-icon,
.input-control .form-control.with-icon {
  padding-left: 40px;
}
.input-control label {
  font-size: 14px;
}
.input-control.form-control-lg,
.input-control .form-control-lg {
  font-size: 15px;
  line-height: 15px;
  height: 50px !important;
}
.input-control.form-control-lg.with-icon,
.input-control .form-control-lg.with-icon {
  padding-left: 50px;
}
.input-control.form-control-sm,
.input-control .form-control-sm {
  height: 30px !important;
}
.input-control.form-control-sm.with-icon,
.input-control .form-control-sm.with-icon {
  padding-left: 30px;
}
.input-control .icon {
  width: 40px;
  height: 40px;
}
.input-control .icon.icon-sm {
  width: 30px;
  height: 30px;
}
.input-control .icon.icon-lg {
  width: 50px;
  height: 50px;
}

.input-box {
  position: relative;
}
.input-box .eye-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/assets/input-control.scss"],"names":[],"mappings":"AAEE;;EAEE,WAAA;EACA,kBAAA;AADJ;AAEI;;EACE,kBAAA;AACN;AAGE;EACE,eAAA;AADJ;AAIE;;EAEE,eAAA;EACA,iBAAA;EACA,uBAAA;AAFJ;AAII;;EACE,kBAAA;AADN;AAKE;;EAEE,uBAAA;AAHJ;AAKI;;EACE,kBAAA;AAFN;AAME;EACE,WAAA;EACA,YAAA;AAJJ;AAMI;EACE,WAAA;EACA,YAAA;AAJN;AAOI;EACE,WAAA;EACA,YAAA;AALN;;AAUA;EACE,kBAAA;AAPF;AAQE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,eAAA;AANJ","sourcesContent":["\n.input-control {\n  &.form-control,\n  .form-control {\n    width: 100%;\n    border-radius: 2px;\n    &.with-icon {\n      padding-left: 40px;\n    }\n  }\n\n  label{\n    font-size: 14px;\n  }\n\n  &.form-control-lg,\n  .form-control-lg {\n    font-size: 15px;\n    line-height: 15px;\n    height: 50px !important;\n\n    &.with-icon {\n      padding-left: 50px;\n    }\n  }\n\n  &.form-control-sm,\n  .form-control-sm {\n    height: 30px !important;\n\n    &.with-icon {\n      padding-left: 30px;\n    }\n  }\n\n  .icon {\n    width: 40px;\n    height: 40px;\n\n    &.icon-sm {\n      width: 30px;\n      height: 30px;\n    }\n\n    &.icon-lg {\n      width: 50px;\n      height: 50px;\n    }\n  }\n}\n\n.input-box {\n  position: relative;\n  .eye-icon {\n    width: 20px;\n    height: 20px;\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
