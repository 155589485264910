import TabPage from "../tabs/TabPage";
import RolesForm from "./RolesForm";

export default function RolesFormWrapper() {
  return (
    <TabPage>
      <RolesForm />
    </TabPage>
  );
}
