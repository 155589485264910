import { BaseApi } from "../BaseApi";
import {
  CreateWordProps,
  DeleteWordProps,
  GetAllWordsProps,
  GetOneWordProps,
  UpdateWordProps,
} from "./WordsDto";

export class WordsApi extends BaseApi {
  public getAllWords(query: GetAllWordsProps) {
    return this.get("Words/GetAll", {
      query: query,
    });
  }

  public getAllUnits() {
    return this.get("Words/GetUnits");
  }

  public getOneWord(query: GetOneWordProps) {
    return this.get("Words/GetOne", {
      query: query,
    });
  }

  public createWord(json: CreateWordProps) {
    return this.post("Words/Create", {
      json: json,
    });
  }

  public updateWord(json: UpdateWordProps) {
    return this.put("Words/Update", {
      json: json,
    });
  }

  public deleteWord(query: DeleteWordProps) {
    return this.delete("Words/Delete", {
      query: query,
    });
  }
}
