import { Form, Formik } from "formik";
import { InputField } from "../form/InputField";
import { GroupBox } from "../ui/GroupBox";
import { TextAreaField } from "../form/TextAreaField";
import Button, { BgColors } from "../ui/Button";

interface Props {
  readonly initialValues: any;
  readonly setInitialValues: (value: any) => void;

  readonly onSubmit: (value: any) => void;
}

export default function WordsForm({ initialValues, setInitialValues, onSubmit }: Props) {
  return (
    <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
      {() => (
        <Form>
          <GroupBox>
            <div className="row">
              <div className="col-6">
                <InputField name="unitNumber" label="Unit number" />
              </div>
              <div className="col-6">
                <InputField name="pageNumber" label="Page number" />
              </div>
              <div className="col-6 mt-2">
                <InputField name="uzbek" label="Uzbek" />
              </div>
              <div className="col-6 mt-2">
                <InputField name="english" label="English" />
              </div>
              <div className="col-12 mt-2">
                <TextAreaField
                  name="_definition"
                  label="Definition"
                  defaultValue={initialValues.definition}
                />
              </div>
              <div className="col-12 mt-2">
                <TextAreaField
                  name="_example"
                  label="Example"
                  defaultValue={initialValues.example}
                />
              </div>
              <div className="col-12 mt-3 d-flex justify-content-end">
                <Button className="px-5 py-2 text-light" bgColor={BgColors.Green} type="submit">
                  Send
                </Button>
              </div>
            </div>
          </GroupBox>
        </Form>
      )}
    </Formik>
  );
}
