import "./assets/auth-form.scss";
import { Form, Formik } from "formik";
import { AuthProps } from "../../api/auth/AuthDto";
import { InputField } from "../form/InputField";
import { useCallback } from "react";
import { update } from "immupdate";
import { useI18n } from "../../i18n/I18nContext";
import { object, string } from "yup";

import Button from "../ui/Button";

interface Props {
  readonly loading?: boolean;
  readonly initialValues: AuthProps;
  readonly setInitialValues: (value: any) => void;
  readonly onSubmit: (value: AuthProps) => void;
}

const validationSchema = object({
  username: string().required("Required!"),
  password: string().required("Required!"),
});

export default function AuthForm({ initialValues, setInitialValues, onSubmit, loading }: Props) {
  const { translate } = useI18n();

  const onChangeUsername = useCallback(
    (value: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          username: value.target.value,
        }),
      );
    },
    [setInitialValues],
  );

  const onChangePassword = useCallback(
    (value: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          password: value.target.value,
        }),
      );
    },
    [setInitialValues],
  );

  return (
    <div className="auth-form">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <h1>{translate("My words")}</h1>
            <InputField
              name="username"
              placeholder={translate("Username")}
              value={initialValues.username}
              onChange={onChangeUsername}
            />
            <InputField
              name="password"
              placeholder={translate("Password")}
              value={initialValues.password}
              onChange={onChangePassword}
              type="password"
            />
            <Button type="submit" loading={loading}>
              {translate("Send")}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
