// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-wrapper {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

table {
  max-width: none;
  table-layout: fixed;
}
table thead {
  position: sticky;
  background-color: #fff;
  top: -8px;
}
table thead th {
  font-weight: bold !important;
  height: 60px;
}

.table-notifications {
  width: 100%;
  height: calc(100% - 60px);
}`, "",{"version":3,"sources":["webpack://./src/components/table/assets/table.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACE,4BAAA;EACA,YAAA;AACN;;AAIA;EACE,WAAA;EACA,yBAAA;AADF","sourcesContent":[".table-wrapper {\n  width: 100%;\n  height: 100%;\n  overflow-x: auto;\n}\n\ntable {\n  max-width: none;\n  table-layout: fixed;\n\n  thead {\n    position: sticky;\n    background-color: #fff;\n    top: -8px;\n\n    th {\n      font-weight: bold !important;\n      height: 60px;\n    }\n  }\n}\n\n.table-notifications {\n  width: 100%;\n  height: calc(100% - 60px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
