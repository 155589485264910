import "./assets/header.scss";
import LogoutButton from "./LogoutButton";

interface Props {
  readonly onChangeMenu: () => void;
  readonly onChangeLogout: () => void;
}

export default function Header({ onChangeMenu, onChangeLogout }: Props) {
  return (
    <header>
      <LogoutButton onClick={onChangeLogout} />
    </header>
  );
}
