import { BaseApi } from "../BaseApi";

export class ClaimsApi extends BaseApi {
  public getAllClaims() {
    return this.get("Claims/GetAllClaims");
  }

  public getAllUserClaims(id: number) {
    return this.get("Claims/GetUserClaims", {
      query: { userId: id },
    });
  }

  public getAllRoleClaims(id: number) {
    return this.get("Claims/GetRoleClaims", {
      query: { roleId: id },
    });
  }

  public addClaimsToUser(json: any) {
    return this.post("Claims/UpdateUserClaims", {
      json,
    });
  }
}
