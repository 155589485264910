import ClaimsTable from "../claims/ClaimsTable";

interface Props {
  readonly claims: any[];
  readonly userClaims: any[];
  readonly selectedRow: (value: any) => void;
}

export default function UsersClaimTable({ claims, userClaims, selectedRow }: Props) {
  return <ClaimsTable claims={claims} localClaims={userClaims} onChangeClaim={selectedRow} />;
}
