import WordsCard from "./WordsCard";

interface Props {
  readonly data: any[];
  readonly loading?: boolean;
  readonly editWord: (value: any) => void;
  readonly deleteWord: (value: any) => void;
}

export default function WordsCards({ data, loading, deleteWord, editWord }: Props) {
  if (loading && data.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h4 className="fw-bold">Loading...</h4>
      </div>
    );
  }

  if (!loading && data.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h4 className="fw-bold">Empty</h4>
      </div>
    );
  }

  return (
    <div className="row">
      {data &&
        data.map((item) => {
          return (
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 py-2">
              <WordsCard item={item} _delete={deleteWord} _edit={editWord} />
            </div>
          );
        })}
    </div>
  );
}
