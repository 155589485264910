// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form {
  width: 500px;
  min-height: 300px;
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgb(202, 194, 194);
  border-top: 4px solid #3e8cbb;
  border-radius: 2px;
  padding: 20px 30px;
}
.auth-form h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
}
.auth-form .auth-lang-select {
  outline: 0;
}
.auth-form input {
  width: 100%;
  margin: 10px 0;
  height: 36px !important;
  outline: none;
  padding: 0 10px;
}
.auth-form button {
  width: 100%;
  height: 36px;
  margin-top: 30px;
  border: transparent;
  background-color: #3e8cbb;
  font-weight: bold;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/auth/assets/auth-form.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,4CAAA;EACA,6BAAA;EACA,kBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,UAAA;AAAJ;AAGE;EACE,WAAA;EACA,cAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,WAAA;AAFJ","sourcesContent":[".auth-form {\n  width: 500px;\n  min-height: 300px;\n  background-color: #fff;\n  box-shadow: 0 1px 1px 1px rgb(202, 194, 194);\n  border-top: 4px solid #3e8cbb;\n  border-radius: 2px;\n  padding: 20px 30px;\n\n  h1 {\n    text-align: center;\n    font-size: 30px;\n    margin-top: 20px;\n  }\n\n  .auth-lang-select {\n    outline: 0;\n  }\n\n  input {\n    width: 100%;\n    margin: 10px 0;\n    height: 36px !important;\n    outline: none;\n    padding: 0 10px;\n  }\n\n  button {\n    width: 100%;\n    height: 36px;\n    margin-top: 30px;\n    border: transparent;\n    background-color: #3e8cbb;\n    font-weight: bold;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
