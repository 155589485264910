import { useState } from "react";

import Modal from "../ui/Modal";
import WordsCardsWrapper from "./WordsCardsWrapper";
import WordsFormWrapper from "./WordsFormWrapper";

export default function WordsTab() {
  const [modal, setModal] = useState(false);

  const [wordId, setWordId] = useState(0);

  return (
    <>
      <WordsCardsWrapper
        clickCreateButton={() => {
          setModal(true);
          setWordId(0);
        }}
        clickEditButton={(value) => {
          setWordId(value.Id);
          setModal(true);
        }}
      />
      <Modal
        show={modal}
        closeHandler={() => setModal(false)}
        className="d-flex justify-content-center align-items-center"
        contentClassName="rounded p-4"
        width="800px"
      >
        <WordsFormWrapper wordId={wordId} />
      </Modal>
    </>
  );
}
