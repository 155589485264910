import "./assets/claims-table.scss";
import { useCallback } from "react";

interface Props {
  readonly claims: any[];
  readonly localClaims: any[];
  readonly onChangeClaim: (value: any) => void;
}

export default function ClaimsTable({ claims, localClaims, onChangeClaim }: Props) {
  const checkClaim = useCallback(
    (claim: any) => {
      return localClaims.filter((i: any) => i.claimType === claim.claimType).length > 0;
    },
    [localClaims],
  );

  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th>Object name</th>
          <th
            scope="col"
            style={{
              width: "100px",
              textAlign: "center",
            }}
          >
            Read
          </th>
          <th
            scope="col"
            style={{
              width: "100px",
              textAlign: "center",
            }}
          >
            Create
          </th>
          <th
            scope="col"
            style={{
              width: "100px",
              textAlign: "center",
            }}
          >
            Update
          </th>
          <th
            scope="col"
            style={{
              width: "100px",
              textAlign: "center",
            }}
          >
            Delete
          </th>
        </tr>
      </thead>
      <tbody>
        {claims.map((item: any, index) => {
          return (
            <tr key={index}>
              <td>{item.name}</td>
              {item.claims.map((claim: any) => {
                return (
                  <td
                    style={{
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={checkClaim(claim)}
                      onChange={() => onChangeClaim(claim)}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
