// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-control {
  height: 38px;
}

.select-picker .select-picker__menu {
  z-index: 10;
}
.select-picker .select-picker__control {
  border: none;
  box-shadow: none;
  min-height: auto;
  height: 36px;
  padding: 0 !important;
}
.select-picker .select-picker__control.select-picker__control--is-disabled {
  background-color: transparent !important;
}
.select-picker .select-picker__control .select-picker__value-container {
  display: flex;
  align-items: start !important;
}
.select-picker .select-picker__control .select-picker__multi-value {
  margin: 0 2px !important;
}
.select-picker .select-picker__control .select-picker__indicators .select-picker__indicator-separator {
  display: none;
}
.select-picker .select-picker__control .select-picker__indicators .select-picker__dropdown-indicator {
  padding: 0;
}
.select-picker.picker-sm .select-picker__value-container {
  height: 28px;
}
.select-picker.picker-lg .select-picker__value-container {
  height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/assets/select-picker.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGE;EACE,WAAA;AAAJ;AAGE;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;AADJ;AAGI;EACE,wCAAA;AADN;AAII;EACE,aAAA;EACA,6BAAA;AAFN;AAKI;EACE,wBAAA;AAHN;AAOM;EACE,aAAA;AALR;AAOM;EACE,UAAA;AALR;AAWI;EACE,YAAA;AATN;AAcI;EACE,YAAA;AAZN","sourcesContent":[".select-control {\n  height: 38px;\n}\n\n.select-picker {\n  .select-picker__menu {\n    z-index: 10;\n  }\n\n  .select-picker__control {\n    border: none;\n    box-shadow: none;\n    min-height: auto;\n    height: 36px;\n    padding: 0 !important;\n\n    &.select-picker__control--is-disabled {\n      background-color: transparent !important;\n    }\n\n    .select-picker__value-container {\n      display: flex;\n      align-items: start !important;\n    }\n\n    .select-picker__multi-value {\n      margin: 0 2px !important;\n    }\n\n    .select-picker__indicators {\n      .select-picker__indicator-separator {\n        display: none;\n      }\n      .select-picker__dropdown-indicator {\n        padding: 0;\n      }\n    }\n  }\n\n  &.picker-sm {\n    .select-picker__value-container {\n      height: 28px;\n    }\n  }\n\n  &.picker-lg {\n    .select-picker__value-container {\n      height: 48px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
