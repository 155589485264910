import { BaseApi } from "../BaseApi";

export class RolesApi extends BaseApi {
  public getAllRoles() {
    return this.get("Roles/GetAll");
  }

  public createRole(json: any) {
    return this.post("Roles/Create", {
      json,
    });
  }
}
