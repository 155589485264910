import { I18nSchema } from "./I18nSchema";

export const I18nEnglish: I18nSchema = {
  ENGLISH_LANGUAGE: "English",
  RUSSIAN_LANGUAGE: "Russian",
  UZBEK_LANGUAGE: "Uzbek",
  SHORT_EN_LANGUAGE: "EN",
  SHORT_RU_LANGUAGE: "RU",
  SHORT_UZ_LANGUAGE: "UZ",

  SIDE_MENU_OBJECTS_TITLE: "Objects",
  SIDE_MENU_REGIONS_TITLE: "Regions",
  SIDE_MENU_PROJECTS_TITLE: "Projects",
  SIDE_MENU_USERS_TITLE: "Users",
  SIDE_MENU_SETTINGS_TITLE: "Settings",
  SIDE_MENU_PRODUCT_TYPES_TITLE: "Type of equipment",
  SIDE_MENU_MAP_TITLE: "Map",
  SIDE_MENU_OBJECT_CLASSIFICATION_TYPE_TITLE: "Object Classification Type",

  ADD_BUTTON_TITLE: "Add",
  BACK_BUTTON_TITLE: "Back",
  SAVE_BUTTON_TITLE: "Save",
  SEND_BUTTON_TITLE: "Send",

  SELECT_PICKER_PLACEHOLDER_TITLE: "All",
  SELECT_PICKER_NO_OPTIONS_MESSAGE: "No options",

  STATUS_SELECT_PICKER_ACTIVE_TITLE: "Active",
  STATUS_SELECT_PICKER_NON_ACTIVE_TITLE: "Nont Active",
  STATUS_SELECT_PICKER_DELETED_TITLE: "Deleted",

  ROLE_SELECT_PICKER_ADMIN_TITLE: "Admin",
  ROLE_SELECT_PICKER_DB_TITLE: "DB",
  ROLE_SELECT_PICKER_TEACHER_TITLE: "Teacher",
  ROLE_SELECT_PICKER_ASSISTANT_TITLE: "Assistant",
  ROLE_SELECT_PICKER_STUDENT_TITLE: "Student",

  SEARCH_INPUT_PLACEHOLDER_TITLE: "Search...",

  LOGOUT_BUTTON_TITLE: "Logout",
  YES_BUTTON_TITLE: "Yes",
  NO_BUTTON_TITLE: "No",
  ADD_QUESTION_BUTTON_TITLE: "Add Question",
  REMOVE_QUESTION_BUTTON_TITLE: "Remove This Question",
  ADD_ANSWER_BUTTON_TITLE: "Add Answer",
  UPLOAD_EXCEL_BUTTON_TITLE: "Upload From Excel",

  SHOW_BUTTON_TITLE: "Show",
  WRITE_BUTTON_TITLE: "Writing",

  USERS_TABLE_USER_ID_COLUMN_TITLE: "Id",
  USERS_TABLE_USER_DATE_COLUMN_TITLE: "Date",
  USERS_TABLE_USER_NAME_COLUMN_TITLE: "Username",
  USERS_TABLE_USER_FULL_NAME_COLUMN_TITLE: "Full Name",
  USERS_TABLE_USER_EMAIL_COLUMN_TITLE: "Email",
  USERS_TABLE_USER_ROLE_COLUMN_TITLE: "Role",
  USERS_TABLE_USER_STATUS_COLUMN_TITLE: "Status",

  USERS_FORM_CRATE_USER_TITLE: "Create New User",
  USERS_FORM_USER_EMAIL_FIELD_TITLE: "User Email",
  USERS_FORM_USER_FIRST_NAME_FIELD_TITLE: "First Name",
  USERS_FORM_USER_LAST_NAME_FIELD_TITLE: "Last Name",
  USERS_FORM_USER_NAME_FIELD_TITLE: "User Name",
  USERS_FORM_USER_ROLE_FIELD_TITLE: "User Role",
  USERS_FORM_USER_PASSWORD_FIELD_TITLE: "User Password",

  EMPTY_TITLE: "Empty",

  SETTINGS_FORM_USER_INFO_TITLE: "User Info",
  SETTINGS_FORM_USER_LANGUAGE_TITLE: "Language",
  SETTINGS_FORM_RESET_PASSWORD_TITLE: "Reset Password",
  SETTINGS_FORM_USER_EMAIL_FIELD_TITLE: "Email",
  SETTINGS_FORM_USER_NAME_FIELD_TITLE: "Username",
  SETTINGS_FORM_USER_PASSWORD_FIELD_TITLE: "Password",
  SETTINGS_FORM_USER_OLD_PASSWORD_FIELD_TITLE: "Old Password",
  SETTINGS_FORM_USER_NEW_PASSWORD_FIELD_TITLE: "New Password",
  SETTINGS_FORM_USER_CONFIRM_PASSWORD_FIELD_TITLE: "Confirm Password",

  SETTINGS_FORM_LANGUAGE_QUESTION_TITLE: "Are you sure you want to change the language?",

  SETTINGS_TABLE_USER_ROLES_TITLE: "User Roles",
  SETTINGS_TABLE_CREATE_LESSON_COLUMN_TITLE: "Create Lesson",
  SETTINGS_TABLE_CREATE_USER_COLUMN_TITLE: "Create User",
  SETTINGS_TABLE_CREATE_TEACHER_COLUMN_TITLE: "Create Teacher",
  SETTINGS_TABLE_CREATE_ADMIN_COLUMN_TITLE: "Create Admin",

  AUTH_ADMIN_TITLE: "Admin",

  AUTH_REQUIRED_USERNAME_TITLE: "Username is Required!",
  AUTH_REQUIRED_PASSWORD_TITLE: "Password si Required!",
  AUTH_FORM_USERNAME_FIELD_TITLE: "Username",
  AUTH_FORM_PASSWORD_FIELD_TITLE: "Password",

  EMPTY_TABLE_TITLE: "Empty",

  OBJECT_FORM_REGION_NAME_FIELD_TITLE: "Region",
  OBJECT_FORM_DISTRICT_NAME_FIELD_TITLE: "District",
  OBJECT_FORM_PROEJCT_NAME_FIELD_TITLE: "Project",
  OBJECT_FORM_NUMBER_OF_ORDER_FIELD_TITLE: "Number of Order",
  OBJECT_FORM_OBJECT_CLASS_TYPE_FIELD_TITLE: "Oject Classification Type",
  OBJECT_FORM_OBJECT_CLASS_FIELD_TITLE: "Object Classification",
  OBJECT_FORM_NAME_FIELD_TITLE: "Name",
  OBJECT_FORM_HOME_FIELD_TITLE: "Home",
  OBJECT_FORM_LONGITUDE_FIELD_TITLE: "Longitude",
  OBJECT_FORM_LATITUDE_FIELD_TITLE: "Latitude",
  OBJECT_FORM_STREET_FIELD_TITLE: "Street",
  OBJECT_FORM_INFO_FIELD_TITLE: "Info",

  OBJECT_TABLE_ID_COLUMN_TITLE: "Id",
  OBJECT_TABLE_CREATED_DATE_COLUMN_TITLE: "Created Date",
  OBJECT_TABLE_UPDATED_DATE_COLUMN_TITLE: "Updated Date",
  OBJECT_TABLE_NAME_COLUMN_TITLE: "Name",
  OBJECT_TABLE_HOME_COLUMN_TITLE: "Home",
  OBJECT_TABLE_LONGITUDE_COLUMN_TITLE: "Longitude",
  OBJECT_TABLE_LATITUDE_COLUMN_TITLE: "Latitude",
  OBJECT_TABLE_STREET_COLUMN_TITLE: "Street",
  OBJECT_TABLE_INFO_COLUMN_TITLE: "Info",

  REGION_TABLE_ID_COLUMN_TITLE: "Id",
  REGION_TABLE_CREATED_DATE_COLUMN_TITLE: "Created Date",
  REGION_TABLE_UPDATED_DATE_COLUMN_TITLE: "Updated Date",
  REGION_TABLE_CREATED_BY_COLUMN_TITLE: "Created By",
  REGION_TABLE_UPDATED_BY_COLUMN_TITLE: "Updated By",
  REGION_TABLE_REGION_NAME_COLUMN_TITLE: "Region Name",
  REGION_TABLE_REGION_INFO_COLUMN_TITLE: "Region Info",
  REGION_TABLE_REGION_DISTRICTS_COLUMN_TITLE: "Region Districts",

  REGION_TABLE_REGION_DISTRICTS_TITLE: "Districts",
};
