// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  width: 100%;
  height: 100%;
  background-color: #3e8cbb;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 40px;
  padding-left: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/header/assets/header.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AACF","sourcesContent":["header {\n  width: 100%;\n  height: 100%;\n  background-color: #3e8cbb;\n  display: flex;\n  align-items: center;\n  justify-content: end;\n  padding-right: 40px;\n  padding-left: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
