import { useI18n } from "../../i18n/I18nContext";
import MyLinksIcon from "../icons/MyLinksIcon";
import Table from "../table/Table";
import Button, { BgColors } from "../ui/Button";

interface Props {
  readonly data: any[];
  readonly clickRole: (value: any) => void;
}

export default function Rolestable({ data, clickRole }: Props) {
  const { translate } = useI18n();

  const headers: any = [
    {
      header: translate("Name"),
      access: "name",
      width: 500,
    },
    {
      header: translate("Normalized name"),
      access: "normalizedName",
      width: 500,
    },
    {
      header: "...",
      access: "details",
      width: 100,
      ceil: (row: any) => {
        return (
          <div className="d-flex gap-2">
            <Button
              onClick={() => clickRole(row)}
              className="py-2 px-2 text-light"
              bgColor={BgColors.Green}
            >
              <MyLinksIcon />
            </Button>
          </div>
        );
      },
    },
  ];

  return <Table headers={headers} data={data} />;
}
