import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { ClaimsApi } from "./ClaimsApi";

interface Props {
  readonly ClaimsApi: ClaimsApi;
}

export function useClaimsApiContext(): Props {
  const data = useApiBase();
  const api = useMemo(() => new ClaimsApi(data), [data]);
  return {
    ClaimsApi: api,
  };
}
