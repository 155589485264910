// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-dropdown::after {
  display: none !important;
}

.my-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.my-dropdown-item {
  display: flex !important;
  gap: 10px;
  align-items: center;
  font-family: "Lato", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal;
  color: #313131 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/assets/action-button.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AAEA;EACE,wCAAA;EACA,uBAAA;AACF;;AAEA;EACE,wBAAA;EACA,SAAA;EACA,mBAAA;EACA,0CAAA;EACA,2BAAA;EACA,kBAAA;EACA,yBAAA;AACF","sourcesContent":[".my-dropdown::after {\n  display: none !important;\n}\n\n.my-dropdown {\n  background-color: transparent !important;\n  border: none !important;\n}\n\n.my-dropdown-item {\n  display: flex !important;\n  gap: 10px;\n  align-items: center;\n  font-family: \"Lato\", sans-serif !important;\n  font-weight: 500 !important;\n  font-style: normal;\n  color: #313131 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
