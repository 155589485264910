// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginator-wrapper {
  display: flex;
  gap: 20px;
}
.paginator-wrapper .paginator-select {
  width: 60px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 1px 1px rgb(202, 194, 194);
  cursor: pointer;
}
.paginator-wrapper .button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.paginator-wrapper .button-group button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: white;
  border: none;
  color: black;
  border-radius: 2px;
  box-shadow: 0 1px 1px 1px rgb(202, 194, 194);
}`, "",{"version":3,"sources":["webpack://./src/components/paginator/assets/paginator.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,4CAAA;EACA,eAAA;AACR;AAEI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAAR;AACQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,4CAAA;AACZ","sourcesContent":[".paginator-wrapper{\n    display: flex;\n    gap: 20px;\n    \n    .paginator-select{\n        width: 60px;\n        height: 30px;\n        outline: none;\n        border: none;\n        border-radius: 2px;\n        box-shadow: 0 1px 1px 1px rgb(202, 194, 194);\n        cursor: pointer;\n    }\n\n    .button-group{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        gap: 10px;\n        button{\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            width: 30px;\n            height: 30px;\n            background-color: white;\n            border: none;\n            color: black;\n            border-radius: 2px;\n            box-shadow: 0 1px 1px 1px rgb(202, 194, 194);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
