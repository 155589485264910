import AppContainerLayout from "../components/app/AppContainerLayout";
import WordsTab from "../components/words/WordsTab";

export default function WordsContainer() {
  return (
    <AppContainerLayout>
      <WordsTab />
    </AppContainerLayout>
  );
}
