// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-page-wrapper {
  width: 100%;
  height: 100%;
}
.tab-page-wrapper .tab-page-header {
  width: 100%;
  height: 60px;
}
.tab-page-wrapper .tab-page-body {
  width: 100%;
  height: calc(100% - 120px);
  border-radius: 2px;
  overflow: hidden;
  overflow-y: auto;
}
.tab-page-wrapper .tab-page-body .tab-page-body-table {
  width: 80%;
  height: 100%;
}
.tab-page-wrapper .tab-page-body .tab-page-body-sidebar {
  width: 20%;
  height: 100%;
}
.tab-page-wrapper .tab-page-footer {
  width: 100%;
  height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/assets/tab-page.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,WAAA;EACA,0BAAA;EAEA,kBAAA;EAEA,gBAAA;EACA,gBAAA;AAFJ;AAII;EACE,UAAA;EACA,YAAA;AAFN;AAKI;EACE,UAAA;EACA,YAAA;AAHN;AAOE;EACE,WAAA;EACA,YAAA;AALJ","sourcesContent":[".tab-page-wrapper {\n  width: 100%;\n  height: 100%;\n\n  .tab-page-header {\n    width: 100%;\n    height: 60px;\n  }\n\n  .tab-page-body {\n    width: 100%;\n    height: calc(100% - 120px);\n    // background-color: #fff;\n    border-radius: 2px;\n    // box-shadow: 0 1px 1px 1px rgb(202, 194, 194);\n    overflow: hidden;\n    overflow-y: auto;\n\n    .tab-page-body-table {\n      width: 80%;\n      height: 100%;\n    }\n\n    .tab-page-body-sidebar {\n      width: 20%;\n      height: 100%;\n    }\n  }\n\n  .tab-page-footer {\n    width: 100%;\n    height: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
