
interface Props{
    readonly size?: number | string | undefined;
    readonly color?: string;
}

export default function MyLinksIcon({
    size = 15,
    color = "white"
}:Props){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size }  height={size} viewBox="0 0 448 512">
            <path d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"/>
        </svg>
    )
}