import "./assets/action-button.scss";
import { useI18n } from "../../i18n/I18nContext";

import Dropdown from "react-bootstrap/Dropdown";
import PencilIcon from "../icons/PencilIcon";
import DeleteIcon from "../icons/DeleteIcon";
import VerticalMenuIcon from "../icons/VerticalMenuIcon";

interface Props {
  readonly onClick: (value: string) => void;
}

export default function ActionButtons({ onClick }: Props) {
  const { translate } = useI18n();

  return (
    <Dropdown>
      <Dropdown.Toggle className="my-dropdown">
        <VerticalMenuIcon color="#313131" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className="my-dropdown-item" onClick={() => onClick("edit")}>
          <PencilIcon color="#313131" /> {translate("Edit")}
        </Dropdown.Item>
        <Dropdown.Item className="my-dropdown-item" onClick={() => onClick("delete")}>
          <DeleteIcon color="#313131" /> {translate("Delete")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
