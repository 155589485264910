import { useCallback, useEffect, useState } from "react";
import { useClaimsApiContext } from "../../api/claims/ClaimsApiContext";

import UsersClaimTable from "./UsersClaimTable";
import Button, { BgColors } from "../ui/Button";

interface Props {
  readonly user: any;
  readonly closeClaimModal: () => void;
}

export default function UsersClaimTableWrapper({ user, closeClaimModal }: Props) {
  const [claims, setClaims] = useState<any[]>([]);
  const [userClaims, setUserClaims] = useState<any[]>([]);

  const { ClaimsApi } = useClaimsApiContext();

  useEffect(() => {
    ClaimsApi.getAllClaims()
      .then((r) => setClaims(r))
      .catch((er) => console.log(er));
  }, [ClaimsApi]);

  useEffect(() => {
    ClaimsApi.getAllUserClaims(user.id)
      .then((r) => setUserClaims(r.claims))
      .catch((er) => console.log(er));
  }, [ClaimsApi, user]);

  const selectedRow = useCallback(
    (value: any) => {
      const hasClaim = userClaims.filter((item: any) => item.claimType === value.claimType);

      if (hasClaim.length > 0) {
        const oldClaims = [...userClaims];
        const myOldClaims = oldClaims.filter((i: any) => i.claimType !== hasClaim[0].claimType);
        setUserClaims(myOldClaims);
      } else {
        const oldClaims = [...userClaims];
        oldClaims.push(value);
        setUserClaims(oldClaims);
      }
    },
    [userClaims],
  );

  const onSubmit = useCallback(
    (value: any) => {
      const json = {
        userId: user.id,
        claims: value,
      };
      ClaimsApi.addClaimsToUser(json)
        .then((r) => {
          console.log(r);
          closeClaimModal();
        })
        .catch((er) => console.log(er));
    },
    [ClaimsApi, user, closeClaimModal],
  );

  return (
    <>
      <UsersClaimTable claims={claims} userClaims={userClaims} selectedRow={selectedRow} />
      <div className="d-flex justify-content-end">
        <Button
          className="px-3 py-1 text-light"
          bgColor={BgColors.Green}
          onClick={() => onSubmit(userClaims)}
        >
          Save
        </Button>
      </div>
    </>
  );
}
