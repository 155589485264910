// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-group {
  border-radius: 2px;
  border: 2px solid rgb(202, 202, 202);
}
.box-group .group-title {
  top: -16px;
  left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/assets/box-group.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oCAAA;AACF;AACE;EACE,UAAA;EACA,UAAA;AACJ","sourcesContent":[".box-group{\n  border-radius: 2px;\n  border: 2px solid rgb(202, 202, 202);\n\n  .group-title {\n    top: -16px;\n    left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
