import { useCallback, useEffect, useMemo, useState } from "react";
import { useWordsApiContext } from "../../api/words/WordsApiContext";
import { Form, Formik } from "formik";
import { noop } from "lodash";
import { InputField } from "../form/InputField";
import { SelectPickerField } from "../form/SelectPrickerField";
import { useSearchParams } from "react-router-dom";
import Button, { BgColors } from "../ui/Button";

import TabPage from "../tabs/TabPage";
import WordsCards from "./WordsCards";

interface Props {
  readonly clickCreateButton: () => void;
  readonly clickEditButton: (value: any) => void;
}

export default function WordsCardsWrapper({ clickCreateButton, clickEditButton }: Props) {
  const { WordsApi } = useWordsApiContext();

  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [units, setUnits] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState("");

  const [search, setSearch] = useSearchParams();

  const unitNumber = useMemo(() => search.get("unitNumber") || 0, [search]);

  useEffect(() => {
    WordsApi.getAllUnits()
      .then((r) => {
        const newUnits: any = [
          {
            label: "All",
            value: 0,
          },
        ];
        // eslint-disable-next-line array-callback-return
        r.map((item: any) => {
          newUnits.push({
            label: `Unit ${item.UnitNumber}`,
            value: item.UnitNumber,
          });
        });
        setUnits(newUnits);
      })
      .catch((er) => console.log(er));
  }, [WordsApi]);

  useEffect(() => {
    setLoading(true);
    WordsApi.getAllWords({ searchValue, pageNumber, unitNumber: Number(unitNumber) })
      .then((r) => {
        setData(r?.data);
        setTotal(r?.total);
        setLoading(false);
      })
      .catch((er) => console.log(er));
  }, [WordsApi, searchValue, pageNumber, unitNumber]);

  const deleteWord = useCallback(
    (value: any) => {
      WordsApi.deleteWord({ id: value.Id })
        .then((res) => {
          window.location.reload();
        })
        .catch((er) => console.log(er));
    },
    [WordsApi],
  );

  return (
    <TabPage
      headerComponent={
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Button
              className="px-4 py-2 text-light"
              bgColor={BgColors.Green}
              onClick={clickCreateButton}
            >
              Create
            </Button>
          </div>
          <div className="w-50">
            <Formik
              initialValues={{
                searchValue: searchValue,
                pageNumber: pageNumber,
                unitNumber: unitNumber,
              }}
              onSubmit={noop}
            >
              {() => (
                <Form>
                  <div className="row ">
                    <div className="col-4">
                      <SelectPickerField
                        name="unitNumber"
                        options={units}
                        // eslint-disable-next-line eqeqeq
                        value={units && units.filter((item: any) => item.value == unitNumber)[0]}
                        placeholder="All units"
                        onChanges={(event: any) => {
                          setSearch(`unitNumber=${event.value}`);
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <InputField
                        name="searchValue"
                        value={searchValue}
                        placeholder="English | Uzbek | Definition | Example"
                        onChange={(event: any) => setSearchValue(event.target.value)}
                      />
                    </div>
                    <div className="col-4">
                      <InputField
                        name="pageNumber"
                        value={pageNumber}
                        placeholder="Page number"
                        onChange={(event: any) => setPageNumber(event.target.value)}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
      footerClassName="d-flex align-items-center justify-content-end"
      footerComponent={<span className="fw-bold">Total: {total}</span>}
    >
      <WordsCards
        data={data}
        loading={loading}
        deleteWord={deleteWord}
        editWord={clickEditButton}
      />
    </TabPage>
  );
}
