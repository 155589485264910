import "./assets/words-card.scss";
import ActionButtons from "../buttons/ActionButton";

interface Props {
  readonly English: string;
  readonly Uzbek: string;
  readonly Definition: string;
  readonly Example: string;
  readonly PageNumber: string;
  readonly UnitNumber: string;
}

interface ItemProps {
  readonly item: Props;
  readonly _edit: (value: any) => void;
  readonly _delete: (value: any) => void;
}

export default function WordsCard({ item, _delete, _edit }: ItemProps) {
  return (
    <div className="card my-2 p-3 h-100">
      <div className="flags-words-buttons">
        <div className="flags-words">
          <div>
            <img
              width="40px"
              src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/2560px-Flag_of_the_United_Kingdom.svg.png"
              alt=""
            />
            <span className="fw-bold"> -- {item.English}</span>
          </div>
          <div>
            <img
              width="40px"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Uzbekistan.svg/1024px-Flag_of_Uzbekistan.svg.png"
              alt=""
            />
            <span className="fw-bold"> -- {item.Uzbek}</span>
          </div>
        </div>
        <div className="words-card-buttons">
          <ActionButtons
            onClick={(value) => {
              if (value === "edit") {
                _edit(item);
              } else if (value === "delete") {
                _delete(item);
              }
            }}
          />
        </div>
      </div>
      <span className="mt-4">
        <b>Definition: </b>
        {item.Definition}
      </span>
      <span>
        <b>Example: </b>
        {item.Example}
      </span>
      <span className="mt-3">
        <b>Unit:</b> {item.UnitNumber}, <b>Page:</b> {item.PageNumber}
      </span>
    </div>
  );
}
