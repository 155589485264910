import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { profileSelector, tokenSelector } from "../reducers/authReducer";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { checkPermission } from "../utils/CheckProfilePermission";

import AppContainer from "./AppContainer";
import UsersContainer from "./UsersContainer";
import AuthContainer from "./AuthContainer";
import RolesContainer from "./RolesContainer";
import WordsContainer from "./WordsContainer";

export default function RootContainer() {
  const token = useShallowEqualSelector(tokenSelector);

  const isAuthorized = useMemo(() => Boolean(token), [token]);

  const profile = useShallowEqualSelector(profileSelector);

  const locatonPath = useLocation().pathname;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized || locatonPath === "/") {
      navigate("auth");
    }
    if (isAuthorized && locatonPath === "/") {
      navigate("/dashboard/words");
    }
  }, [isAuthorized, navigate, locatonPath]);

  return (
    <Routes>
      {isAuthorized && (
        <Route path="dashboard" element={<AppContainer />}>
          <Route path="words/:tab?" element={<WordsContainer />} />
          {checkPermission(profile, "GetAllUsers") && (
            <Route path="users/:tab?" element={<UsersContainer />} />
          )}

          {checkPermission(profile, "GetRolesProducts") && (
            <Route path="roles/:tab?" element={<RolesContainer />} />
          )}
        </Route>
      )}
      <Route path="/auth" element={<AuthContainer />} />
    </Routes>
  );
}
