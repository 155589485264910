import { useI18n } from "../../i18n/I18nContext";
import Button, { BgColors } from "../ui/Button";

import Table from "../table/Table";
import PencilIcon from "../icons/PencilIcon";
import MyLinksIcon from "../icons/MyLinksIcon";

interface Props {
  readonly data: any;
  readonly loading: boolean;
  readonly deleteUser: (value: any) => void;
  readonly editDocument: (value: any) => void;
  readonly openClaimsModal: (value: any) => void;
}

export default function UsersTable({
  data,
  loading,
  deleteUser,
  openClaimsModal,
  editDocument,
}: Props) {
  const { translate } = useI18n();
  const headers: any = [
    {
      header: translate("USERS_TABLE_USER_ID_COLUMN_TITLE"),
      access: "id",
      width: 100,
    },
    {
      header: translate("USERS_TABLE_USER_EMAIL_COLUMN_TITLE"),
      access: "email",
      width: 400,
    },
    {
      header: translate("Phone number"),
      access: "phoneNumber",
      width: 300,
    },
    {
      header: translate("USERS_TABLE_USER_NAME_COLUMN_TITLE"),
      access: "userName",
      width: 300,
    },
    {
      header: translate("Role"),
      access: "roles",
      width: 300,
      ceil: (row: any) => {
        return (
          <div className="d-flex gap-2">
            {row?.roles &&
              row?.roles?.map((item: any) => {
                return <span className="bg-success px-2 py-1 rounded text-light">{item}</span>;
              })}
          </div>
        );
      },
    },
    {
      header: "...",
      access: "details",
      width: 100,
      searchHidden: true,
      ceil: (row: any) => {
        return (
          <div className="d-flex gap-2">
            <Button
              onClick={() => editDocument(row)}
              className="py-2 px-2 text-light"
              bgColor={BgColors.Yellow}
            >
              <PencilIcon />
            </Button>
            <Button
              onClick={() => openClaimsModal(row)}
              className="py-2 px-2 text-light"
              bgColor={BgColors.Green}
            >
              <MyLinksIcon />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      loading={loading}
      headers={headers}
      data={data}
      selectRowCheckbox={deleteUser}
      withCheckbox
    />
  );
}
