import { I18nSchema } from "./I18nSchema";
import { I18nEnglish } from "./I18nEnglish";

export const I18nUzbek: I18nSchema = {
  ...I18nEnglish,

  SIDE_MENU_OBJECTS_TITLE: "Obyektlar",
  SIDE_MENU_REGIONS_TITLE: "Viloyatlar",
  SIDE_MENU_PROJECTS_TITLE: "Loyihlar",
  SIDE_MENU_USERS_TITLE: "Foydalanuvchilar",
  SIDE_MENU_SETTINGS_TITLE: "Sozlamalar",
  SIDE_MENU_PRODUCT_TYPES_TITLE: "Jihozlar turi",
  SIDE_MENU_MAP_TITLE: "Xarita",
  SIDE_MENU_OBJECT_CLASSIFICATION_TYPE_TITLE: "Loyihani tasniflash turi",

  ADD_BUTTON_TITLE: "Qo'shish",
  BACK_BUTTON_TITLE: "Orqaga",
  SAVE_BUTTON_TITLE: "Saqlash",
  SEND_BUTTON_TITLE: "Yuborish",

  LOGOUT_BUTTON_TITLE: "Chiqish",

  SEARCH_INPUT_PLACEHOLDER_TITLE: "Qidirish...",

  SELECT_PICKER_PLACEHOLDER_TITLE: "Barchasi",
  SELECT_PICKER_NO_OPTIONS_MESSAGE: "Imkoniyat yo’q",

  STATUS_SELECT_PICKER_ACTIVE_TITLE: "Faol",
  STATUS_SELECT_PICKER_NON_ACTIVE_TITLE: "Faol emas",
  STATUS_SELECT_PICKER_DELETED_TITLE: "Oʻchirildi",

  ROLE_SELECT_PICKER_ADMIN_TITLE: "Admin",
  ROLE_SELECT_PICKER_DB_TITLE: "MB",
  ROLE_SELECT_PICKER_TEACHER_TITLE: "O'qituvchi",
  ROLE_SELECT_PICKER_ASSISTANT_TITLE: "Assistent",
  ROLE_SELECT_PICKER_STUDENT_TITLE: "Talaba",

  YES_BUTTON_TITLE: "Ha",
  NO_BUTTON_TITLE: "Yo'q",

  SHOW_BUTTON_TITLE: "Ko'rsatish",
  WRITE_BUTTON_TITLE: "Yozish",

  ADD_QUESTION_BUTTON_TITLE: "Savol qo'shish",
  REMOVE_QUESTION_BUTTON_TITLE: "Ushbu savolni o'chirish",
  ADD_ANSWER_BUTTON_TITLE: "Javob qo'shish",

  EMPTY_TITLE: "Bo'sh",

  USERS_TABLE_USER_ID_COLUMN_TITLE: "Id",
  USERS_TABLE_USER_DATE_COLUMN_TITLE: "Sana",
  USERS_TABLE_USER_NAME_COLUMN_TITLE: "Foydalanuvchi nomi",
  USERS_TABLE_USER_FULL_NAME_COLUMN_TITLE: "To'liq ism",
  USERS_TABLE_USER_EMAIL_COLUMN_TITLE: "E-pochta",
  USERS_TABLE_USER_ROLE_COLUMN_TITLE: "Rol",
  USERS_TABLE_USER_STATUS_COLUMN_TITLE: "Holat",

  USERS_FORM_CRATE_USER_TITLE: "Yangi foydalanuvchi yaratish",
  USERS_FORM_USER_EMAIL_FIELD_TITLE: "Foydalanuvchi elektron pochtasi",
  USERS_FORM_USER_NAME_FIELD_TITLE: "Foydalanuvchi nomi",
  USERS_FORM_USER_ROLE_FIELD_TITLE: "Foydalanuvchi roli",
  USERS_FORM_USER_PASSWORD_FIELD_TITLE: "Foydalanuvchi paroli",

  SETTINGS_FORM_USER_INFO_TITLE: "Foydalanuvchi haqida ma'lumot",
  SETTINGS_FORM_USER_LANGUAGE_TITLE: "Til",
  SETTINGS_FORM_RESET_PASSWORD_TITLE: "Parolni tiklash",
  SETTINGS_FORM_USER_EMAIL_FIELD_TITLE: "E-pochta",
  SETTINGS_FORM_USER_NAME_FIELD_TITLE: "Foydalanuvchi nomi",
  SETTINGS_FORM_USER_PASSWORD_FIELD_TITLE: "Parol",
  SETTINGS_FORM_USER_OLD_PASSWORD_FIELD_TITLE: "Eski parol",
  SETTINGS_FORM_USER_NEW_PASSWORD_FIELD_TITLE: "Yangi parol",
  SETTINGS_FORM_USER_CONFIRM_PASSWORD_FIELD_TITLE: "Parolni tasdiqlang",

  SETTINGS_FORM_LANGUAGE_QUESTION_TITLE: "Chindan ham tilni o'zgartirishni xoxlaysizmi?",

  SETTINGS_TABLE_USER_ROLES_TITLE: "Foydalanuvchi rollari",
  SETTINGS_TABLE_CREATE_LESSON_COLUMN_TITLE: "Dars yaratish",
  SETTINGS_TABLE_CREATE_USER_COLUMN_TITLE: "Foydalanuvchi yaratish",
  SETTINGS_TABLE_CREATE_TEACHER_COLUMN_TITLE: "O'qituvchi yaratish",
  SETTINGS_TABLE_CREATE_ADMIN_COLUMN_TITLE: "Administrator yaratish",

  AUTH_ADMIN_TITLE: "Admin",

  AUTH_FORM_USERNAME_FIELD_TITLE: "Foydalanuvchi nomi",
  AUTH_FORM_PASSWORD_FIELD_TITLE: "Parol",
  AUTH_REQUIRED_USERNAME_TITLE: "Foydalanuvchi nomini kiriting!",
  AUTH_REQUIRED_PASSWORD_TITLE: "Parolni kiriting",

  OBJECT_FORM_REGION_NAME_FIELD_TITLE: "Viloyat",
  OBJECT_FORM_DISTRICT_NAME_FIELD_TITLE: "Tuman",
  OBJECT_FORM_PROEJCT_NAME_FIELD_TITLE: "Loyiha",
  OBJECT_FORM_NUMBER_OF_ORDER_FIELD_TITLE: "Buyurtma raqami",
  OBJECT_FORM_OBJECT_CLASS_TYPE_FIELD_TITLE: "Obyekt tasnifi turi",
  OBJECT_FORM_OBJECT_CLASS_FIELD_TITLE: "Obyekt tasnifi",
  OBJECT_FORM_HOME_FIELD_TITLE: "Uy",
  OBJECT_FORM_NAME_FIELD_TITLE: "Nomi",
  OBJECT_FORM_LONGITUDE_FIELD_TITLE: "Uzunlik",
  OBJECT_FORM_LATITUDE_FIELD_TITLE: "Kenlik",
  OBJECT_FORM_STREET_FIELD_TITLE: "Ko‘cha",
  OBJECT_FORM_INFO_FIELD_TITLE: "Ma'lumot",

  OBJECT_TABLE_ID_COLUMN_TITLE: "Id",
  OBJECT_TABLE_CREATED_DATE_COLUMN_TITLE: "Yaratilgan sana",
  OBJECT_TABLE_UPDATED_DATE_COLUMN_TITLE: "Yangilangan sana",
  OBJECT_TABLE_HOME_COLUMN_TITLE: "Uy",
  OBJECT_TABLE_NAME_COLUMN_TITLE: "Nomi",
  OBJECT_TABLE_LONGITUDE_COLUMN_TITLE: "Uzunlik",
  OBJECT_TABLE_LATITUDE_COLUMN_TITLE: "Kenlik",
  OBJECT_TABLE_STREET_COLUMN_TITLE: "Ko‘cha",
  OBJECT_TABLE_INFO_COLUMN_TITLE: "Ma'lumot",

  REGION_TABLE_ID_COLUMN_TITLE: "Id",
  REGION_TABLE_CREATED_DATE_COLUMN_TITLE: "Yaratilgan vaqti",
  REGION_TABLE_UPDATED_DATE_COLUMN_TITLE: "Yangilangan vaqti",
  REGION_TABLE_CREATED_BY_COLUMN_TITLE: "Tomonidan yaratildi",
  REGION_TABLE_UPDATED_BY_COLUMN_TITLE: "Tomonidan yangilandi",
  REGION_TABLE_REGION_NAME_COLUMN_TITLE: "Viloyat nomi",
  REGION_TABLE_REGION_INFO_COLUMN_TITLE: "Viloyat haqida ma'lumot",
  REGION_TABLE_REGION_DISTRICTS_COLUMN_TITLE: "Viloyat tumanlari",

  REGION_TABLE_REGION_DISTRICTS_TITLE: "Tumanlar",
};
