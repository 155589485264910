import { useCallback, useEffect, useState } from "react";
import { useClaimsApiContext } from "../../api/claims/ClaimsApiContext";

import RolesClaimsTable from "./RolesClaimsTable";
import Button, { BgColors } from "../ui/Button";

interface Props {
  readonly roleId: any;
  readonly closeClaimModal: () => void;
}

export default function RolesClaimsTableWrapper({ roleId, closeClaimModal }: Props) {
  const [claims, setClaims] = useState<any[]>([]);
  const [roleClaims, setRoleClaims] = useState<any[]>([]);

  const { ClaimsApi } = useClaimsApiContext();

  useEffect(() => {
    ClaimsApi.getAllClaims()
      .then((r) => setClaims(r))
      .catch((er) => console.log(er));
  }, [ClaimsApi]);

  useEffect(() => {
    ClaimsApi.getAllRoleClaims(roleId)
      .then((r) => setRoleClaims(r))
      .catch((er) => console.log(er));
  }, [ClaimsApi, roleId]);

  const selectedRow = useCallback(
    (value: any) => {
      const hasClaim = roleClaims.filter((item: any) => item.claimType === value.claimType);

      if (hasClaim.length > 0) {
        const oldClaims = [...roleClaims];
        const myOldClaims = oldClaims.filter((i: any) => i.claimType !== hasClaim[0].claimType);
        setRoleClaims(myOldClaims);
      } else {
        const oldClaims = [...roleClaims];
        oldClaims.push(value);
        setRoleClaims(oldClaims);
      }
    },
    [roleClaims],
  );

  const onSubmit = useCallback(
    (value: any) => {
      const json = {
        roleId: roleId,
        claims: value,
      };
      ClaimsApi.addClaimsToUser(json)
        .then((r) => {
          console.log(r);
          closeClaimModal();
        })
        .catch((er) => console.log(er));
    },
    [ClaimsApi, roleId, closeClaimModal],
  );

  return (
    <>
      <RolesClaimsTable claims={claims} roleClaims={roleClaims} selectedRow={selectedRow} />{" "}
      <div className="d-flex justify-content-end">
        <Button
          className="px-3 py-1 text-light"
          bgColor={BgColors.Green}
          onClick={() => onSubmit(roleClaims)}
        >
          Save
        </Button>
      </div>
    </>
  );
}
