import { I18nSchema } from "./I18nSchema";
import { I18nEnglish } from "./I18nEnglish";

export const I18nRussian: I18nSchema = {
  ...I18nEnglish,

  ENGLISH_LANGUAGE: "Английский",
  RUSSIAN_LANGUAGE: "Русский",
  UZBEK_LANGUAGE: "Узбекский",
  SHORT_EN_LANGUAGE: "EN",
  SHORT_RU_LANGUAGE: "RU",
  SHORT_UZ_LANGUAGE: "UZ",

  SIDE_MENU_OBJECTS_TITLE: "Объекты",
  SIDE_MENU_REGIONS_TITLE: "Регионы",
  SIDE_MENU_PROJECTS_TITLE: "Проекты",
  SIDE_MENU_USERS_TITLE: "Пользователи",
  SIDE_MENU_SETTINGS_TITLE: "Настройки",
  SIDE_MENU_PRODUCT_TYPES_TITLE: "Тип оборудования",
  SIDE_MENU_MAP_TITLE: "Карта",
  SIDE_MENU_OBJECT_CLASSIFICATION_TYPE_TITLE: "Тип классификации объектов",

  ADD_BUTTON_TITLE: "Добавить",
  BACK_BUTTON_TITLE: "Назад",
  SAVE_BUTTON_TITLE: "Cохранять",
  SEND_BUTTON_TITLE: "Отправлять",
  LOGOUT_BUTTON_TITLE: "Выйти",

  SELECT_PICKER_PLACEHOLDER_TITLE: "Все",
  SELECT_PICKER_NO_OPTIONS_MESSAGE: "Нет опций",

  STATUS_SELECT_PICKER_ACTIVE_TITLE: "Активно",
  STATUS_SELECT_PICKER_NON_ACTIVE_TITLE: "Неактивно",
  STATUS_SELECT_PICKER_DELETED_TITLE: "Удален",

  SEARCH_INPUT_PLACEHOLDER_TITLE: "Поиск...",

  ROLE_SELECT_PICKER_ADMIN_TITLE: "Администратор",
  ROLE_SELECT_PICKER_DB_TITLE: "БД",
  ROLE_SELECT_PICKER_TEACHER_TITLE: "Учитель",
  ROLE_SELECT_PICKER_ASSISTANT_TITLE: "Помощник",
  ROLE_SELECT_PICKER_STUDENT_TITLE: "Студент",

  YES_BUTTON_TITLE: "Да",
  NO_BUTTON_TITLE: "Нет",

  ADD_QUESTION_BUTTON_TITLE: "Добавить вопрос",
  REMOVE_QUESTION_BUTTON_TITLE: "Удалить этот вопрос",
  ADD_ANSWER_BUTTON_TITLE: "Добавить ответ",

  SHOW_BUTTON_TITLE: "Показывать",
  WRITE_BUTTON_TITLE: "Письмо",

  EMPTY_TITLE: "Пустой",

  USERS_TABLE_USER_ID_COLUMN_TITLE: "Ид",
  USERS_TABLE_USER_DATE_COLUMN_TITLE: "Дата",
  USERS_TABLE_USER_NAME_COLUMN_TITLE: "Имя пользователя",
  USERS_TABLE_USER_FULL_NAME_COLUMN_TITLE: "Полное имя",
  USERS_TABLE_USER_EMAIL_COLUMN_TITLE: "Электронная почта",
  USERS_TABLE_USER_ROLE_COLUMN_TITLE: "Роль",
  USERS_TABLE_USER_STATUS_COLUMN_TITLE: "Положение дел",

  USERS_FORM_CRATE_USER_TITLE: "Создать нового пользователя",
  USERS_FORM_USER_EMAIL_FIELD_TITLE: "Электронная почта пользователя",
  USERS_FORM_USER_FIRST_NAME_FIELD_TITLE: "Имя",
  USERS_FORM_USER_LAST_NAME_FIELD_TITLE: "Фамилия",
  USERS_FORM_USER_NAME_FIELD_TITLE: "Имя пользователя",
  USERS_FORM_USER_ROLE_FIELD_TITLE: "Роль пользователя",
  USERS_FORM_USER_PASSWORD_FIELD_TITLE: "Пароль пользователя",

  SETTINGS_FORM_USER_INFO_TITLE: "Информация о пользователе",
  SETTINGS_FORM_USER_LANGUAGE_TITLE: "Язык",
  SETTINGS_FORM_RESET_PASSWORD_TITLE: "Сбросить пароль",
  SETTINGS_FORM_USER_EMAIL_FIELD_TITLE: "Электронная почта",
  SETTINGS_FORM_USER_NAME_FIELD_TITLE: "Имя пользователя",
  SETTINGS_FORM_USER_PASSWORD_FIELD_TITLE: "Пароль",
  SETTINGS_FORM_USER_OLD_PASSWORD_FIELD_TITLE: "Старый пароль",
  SETTINGS_FORM_USER_NEW_PASSWORD_FIELD_TITLE: "Новый пароль",
  SETTINGS_FORM_USER_CONFIRM_PASSWORD_FIELD_TITLE: "Подтвердите пароль",

  SETTINGS_FORM_LANGUAGE_QUESTION_TITLE: "Вы уверены, что хотите изменить язык?",

  SETTINGS_TABLE_USER_ROLES_TITLE: "Роли пользователей",
  SETTINGS_TABLE_CREATE_LESSON_COLUMN_TITLE: "Создать урок",
  SETTINGS_TABLE_CREATE_USER_COLUMN_TITLE: "Создать пользователя",
  SETTINGS_TABLE_CREATE_TEACHER_COLUMN_TITLE: "Создать учителя",
  SETTINGS_TABLE_CREATE_ADMIN_COLUMN_TITLE: "Создать администратора",

  AUTH_ADMIN_TITLE: "Админ",

  AUTH_REQUIRED_USERNAME_TITLE: "Введите Имя пользователя",
  AUTH_REQUIRED_PASSWORD_TITLE: "Введите пароль",
  AUTH_FORM_USERNAME_FIELD_TITLE: "Имя пользователя",
  AUTH_FORM_PASSWORD_FIELD_TITLE: "Пароль",

  OBJECT_FORM_REGION_NAME_FIELD_TITLE: "Регион",
  OBJECT_FORM_DISTRICT_NAME_FIELD_TITLE: "Район",
  OBJECT_FORM_PROEJCT_NAME_FIELD_TITLE: "Проект",
  OBJECT_FORM_NUMBER_OF_ORDER_FIELD_TITLE: "Номер заказа",
  OBJECT_FORM_OBJECT_CLASS_TYPE_FIELD_TITLE: "Тип классификации объекта",
  OBJECT_FORM_OBJECT_CLASS_FIELD_TITLE: "Классификация объектов",
  OBJECT_FORM_HOME_FIELD_TITLE: "Дом",
  OBJECT_FORM_NAME_FIELD_TITLE: "Називание",
  OBJECT_FORM_LONGITUDE_FIELD_TITLE: "Долгота",
  OBJECT_FORM_LATITUDE_FIELD_TITLE: "Широта",
  OBJECT_FORM_STREET_FIELD_TITLE: "Улица",
  OBJECT_FORM_INFO_FIELD_TITLE: "Информация",

  OBJECT_TABLE_ID_COLUMN_TITLE: "Ид",
  OBJECT_TABLE_CREATED_DATE_COLUMN_TITLE: "Дата создания",
  OBJECT_TABLE_UPDATED_DATE_COLUMN_TITLE: "Дата обновления",
  OBJECT_TABLE_NAME_COLUMN_TITLE: "Називание",
  OBJECT_TABLE_HOME_COLUMN_TITLE: "Дом",
  OBJECT_TABLE_LONGITUDE_COLUMN_TITLE: "Долгота",
  OBJECT_TABLE_LATITUDE_COLUMN_TITLE: "Широта",
  OBJECT_TABLE_STREET_COLUMN_TITLE: "Улица",
  OBJECT_TABLE_INFO_COLUMN_TITLE: "Информация",

  REGION_TABLE_ID_COLUMN_TITLE: "Ид",
  REGION_TABLE_CREATED_DATE_COLUMN_TITLE: "Время создания",
  REGION_TABLE_UPDATED_DATE_COLUMN_TITLE: "Время обновления",
  REGION_TABLE_CREATED_BY_COLUMN_TITLE: "Автор",
  REGION_TABLE_UPDATED_BY_COLUMN_TITLE: "Обновлено",
  REGION_TABLE_REGION_NAME_COLUMN_TITLE: "Имя региона",
  REGION_TABLE_REGION_INFO_COLUMN_TITLE: "Информация о регионе",
  REGION_TABLE_REGION_DISTRICTS_COLUMN_TITLE: "Региональные округа",

  REGION_TABLE_REGION_DISTRICTS_TITLE: "Районы",
};
